// @flow strict
import React from 'react';
import styles from './CommunityButton.module.scss';

const CommunityButton = () => (
  <a href="https://discord.gg/WRGYhCr">
  <div className={styles['communityButton']}>
    Join the Community Chat
  </div></a>
);

export default CommunityButton;
