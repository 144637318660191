import React, { useRef, useEffect } from 'react';
import styles from './Page.module.scss';
import Comments from '../Post/Comments';

type Props = {
  title?: string,
  children: React.Node,
  enableComments: Boolean
};

const Page = ({
  title, children, enableComments, slug
}: Props) => {
  const pageRef = useRef();

  useEffect(() => {
    pageRef.current.scrollIntoView();
  });

  let comments;
  console.log(slug);

  if (enableComments) {
    comments = <Comments postSlug={slug} postTitle={title} />;
  } else {
    comments = <div className="no-comments"></div>;
  }

  return (
    <div ref={pageRef} className={styles['page']}>
      <div className={styles['page__inner']}>
        { title && <h1 className={styles['page__title']}>{title}</h1>}
        <div className={styles['page__body']}>
          {children}
        </div>
        {comments}
      </div>
    </div>
  );
};

export default Page;